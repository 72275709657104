export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-service/:pageId/:accountType',
        component: () => import('@/views/page/company/core-accounting/inventory/product-service/List.vue'),
        name: 'inventory-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-specification/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/product-service/SpecificationList.vue'),
        name: 'specification-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase/List.vue'),
        name: 'purchase-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase/Add.vue'),
        name: 'purchase-bill-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill-pharmacy/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-pharmacy/Add.vue'),
        name: 'purchase-bill-pharmacy',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill/:pageId/edit/:purchaseId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase/Edit.vue'),
        name: 'purchase-bill-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill-2/:pageId/edit/:purchaseId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-pharmacy/Edit.vue'),
        name: 'purchase-bill-edit-2',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill-pay/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase/BillPay.vue'),
        name: 'purchase-bill-pay',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-return-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-return/List.vue'),
        name: 'purchase-return-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill-manager/:pageId',
        component: () => import('@/views/page/company/hospital/purchase-return/List.vue'),
        name: 'purchase-bill-manager',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill-manager/:pageId/:billId/return',
        component: () => import('@/views/page/company/hospital/purchase-return/Return.vue'),
        name: 'purchase-bill-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill-manager/:pageId/:billId/partial-return',
        component: () => import('@/views/page/company/hospital/purchase-return/Return.vue'),
        name: 'purchase-bill-partial-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-return-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-return/Add.vue'),
        name: 'purchase-return-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-return/:pageId/edit/:purchaseReturnId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-return/Edit.vue'),
        name: 'purchase-return-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-return/:pageId/show/:purchaseReturnId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-return/Print.vue'),
        name: 'purchase-return-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/List.vue'),
        name: 'sales-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/service-sales-register/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/SalesRegisterList.vue'),
        name: 'service-sales-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-sales-register/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/product-sales-register/List.vue'),
        name: 'product-sales-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-money-receipt/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/pending-money-receipt/List.vue'),
        name: 'pending-money-receipt',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-invoice-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/AddBill.vue'),
        name: 'sales-invoice-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-invoice/:pageId/edit/:salesId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/EditBill.vue'),
        name: 'sales-invoice-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-invoice/:pageId/show/:salesId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/Print.vue'),
        name: 'sales-invoice-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-invoice-pay/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/MoneyReceipt.vue'),
        name: 'sales-invoice-receipt',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-return-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-return/List.vue'),
        name: 'sales-return-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-return-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-return/Add.vue'),
        name: 'sales-return-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pay-bill/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/pay-bill/List.vue'),
        name: 'pay-bill-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pay-bill/:pageId/edit/:paymentId',
        component: () => import('@/views/page/company/core-accounting/inventory/pay-bill/Edit.vue'),
        name: 'pay-bill-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/money-receipt/List.vue'),
        name: 'money-receipt-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt/:pageId/edit/:receiptId',
        component: () => import('@/views/page/company/core-accounting/inventory/money-receipt/Edit.vue'),
        name: 'money-receipt-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt/:pageId/print/:receiptId',
        component: () => import('@/views/page/company/core-accounting/inventory/money-receipt/Print.vue'),
        name: 'money-receipt-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt-return-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/money-receipt/ReturnList.vue'),
        name: 'money-receipt-return-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt/:pageId/details/:receiptId',
        component: () => import('@/views/page/company/core-accounting/inventory/money-receipt/Details.vue'),
        name: 'money-receipt-details',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt/:pageId/return/:receiptId',
        component: () => import('@/views/page/company/core-accounting/inventory/money-receipt/ReturnReceipt.vue'),
        name: 'money-receipt-return',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-register/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/inventory-reports/StockRegister.vue'),
        name: 'stock-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-register-old/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/inventory-reports/StockRegister.backup.vue'),
        name: 'stock-register-old',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-commission-report-summary/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/inventory-reports/SalesCommissionReportSummary.vue'),
        name: 'sales-commission-report-summary',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-commission-report-details/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/inventory-reports/SalesCommissionReportDetails.vue'),
        name: 'sales-commission-report-details',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-ledger/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/inventory-reports/StockLedger.vue'),
        name: 'stock-ledger',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-adjustment/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/inventory-reports/StockAdjustment.vue'),
        name: 'stock-adjustment-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-order-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-order/List.vue'),
        name: 'sales-order-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-order-invoice/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-order-invoice/List.vue'),
        name: 'sales-order-invoice',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-order-approval/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-order/List.vue'),
        name: 'sales-order-approval',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/order-sales/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/order-sales/List.vue'),
        name: 'order-sales',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-sales-order/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/order-sales/AddOrder.vue'),
        name: 'product-sales-order',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/approved-sales-order/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/approved-sales-order/List.vue'),
        name: 'approved-sales-orders',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-order-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-order/AddBill.vue'),
        name: 'sales-order-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-order/:pageId/edit/:salesId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-order/EditBill.vue'),
        name: 'sales-order-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-project-initiate/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/project-initiate/List.vue'),
        name: 'pending-project-initiate',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/create-project-initiate/:pageId/saleOrder/:soId/add',
        component: () => import('@/views/page/company/core-accounting/inventory/project-initiate/Create.vue'),
        name: 'create-project-initiate',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/edit-project-initiate/:pageId/saleOrder/:soId/edit/:projectId',
        component: () => import('@/views/page/company/core-accounting/inventory/project-initiate/Create.vue'),
        name: 'edit-project-initiate',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-delivery-challan-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/delivery-challan/List.vue'),
        name: 'pending-delivery-challan-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/create-challan/:pageId/add/:salesId',
        component: () => import('@/views/page/company/core-accounting/inventory/approved-sales-order/CreateChallan.vue'),
        name: 'create-challan',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/create-invoice-from-challan/:pageId/add/:challanId',
        component: () => import('@/views/page/company/core-accounting/inventory/delivery-challan/CreateInvoice.vue'),
        name: 'create-invoice-from-challan',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/delivery-challan-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/delivery-challan/List.vue'),
        name: 'delivery-challan-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/delivery-challan/:pageId/edit/:salesOrderId',
        component: () => import('@/views/page/company/core-accounting/inventory/delivery-challan/EditChallan.vue'),
        name: 'delivery-challan-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/delivery-challan/:pageId/view/:challanId',
        component: () => import('@/views/page/company/core-accounting/inventory/delivery-challan/ViewChallan.vue'),
        name: 'delivery-challan-details',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/import-product-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/product-service/ImportProductList.vue'),
        name: 'import-product-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/upload-product/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/product-service/UploadProduct.vue'),
        name: 'upload-product',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-service-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/product-service/ProductList.vue'),
        name: 'product-service-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/invoice-electronics/:pageId/list',
        component: () => import('@/views/page/company/core-accounting/inventory/invoice-electronics/ElectronicInvoiceList.vue'),
        name: 'invoice-electronics-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/invoice-electronics/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/invoice-electronics/ElectronicInvoice.vue'),
        name: 'invoice-electronics',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/return-electronics-invoice/:invoiceId/partial',
        component: () => import('@/views/page/company/core-accounting/inventory/invoice-electronics/BillReturn.vue'),
        name: 'invoice-return-electronics-partial',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/bill-manager/return-electronics-invoice/:invoiceId',
        component: () => import('@/views/page/company/core-accounting/inventory/invoice-electronics/ReturnElectronicsInvoice.vue'),
        name: 'invoice-return-electronics',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pi-order-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/order-management/List.vue'),
        name: 'pi-order-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pi-order-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/order-management/AddOrder.vue'),
        name: 'pi-order-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pi-order/:pageId/edit/:salesId',
        component: () => import('@/views/page/company/core-accounting/inventory/order-management/EditOrder.vue'),
        name: 'pi-order-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/order-tracker/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/order-management/OrderTracker.vue'),
        name: 'order-tracker',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-offer-setup/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-offer/SalesOfferSetup.vue'),
        name: 'sales-offer-setup',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-costing-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/product-costing/List.vue'),
        name: 'product-costing-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-costing-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/product-costing/Add.vue'),
        name: 'product-costing-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-costing-edit/:pageId/edit/:productCostingId',
        component: () => import('@/views/page/company/core-accounting/inventory/product-costing/Edit.vue'),
        name: 'product-costing-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/production-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/production/List.vue'),
        name: 'production-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/production-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/production/Add.vue'),
        name: 'production-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/production-edit/:pageId/edit/:productionId',
        component: () => import('@/views/page/company/core-accounting/inventory/production/Edit.vue'),
        name: 'production-edit',
        meta: {
            requiresAuth: true
        }
    },
]
